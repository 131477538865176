import PropTypes from "prop-types"
import React from "react"

import "../sass/components/_footer.scss"

const Footer = ({ siteTitle }) => (
  <footer className="footer">
    © {new Date().getFullYear()},{` `}
    <a href="/" data-text={siteTitle}>{siteTitle}</a>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
