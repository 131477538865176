import React, { useRef, useState } from 'react'
import { Canvas, useFrame } from 'react-three-fiber'

function Box(props) {
    const { backwards } = props;
    // This reference will give us direct access to the mesh
    const mesh = useRef()
  
    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)
  
    // Rotate mesh every frame, this is outside of React without overhead
    useFrame(() => (mesh.current.rotation.x = backwards ? mesh.current.rotation.y += 0.01 : mesh.current.rotation.y -= 0.01))
  
    return (
      <mesh
        {...props}
        ref={mesh}
        // scale={active ? [1.5, 1.5, 1.5] : [10, 1, 1]}
        scale={backwards ? [1, 1, 9] : [9, 1, 1]}
        onClick={(e) => setActive(!active)}
        onPointerOver={(e) => setHover(true)}
        onPointerOut={(e) => setHover(false)}>
        <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
        <meshStandardMaterial attach="material" color={hovered ? '#670000' : '#b30000'} />
      </mesh>
    )
  }
  

const ThreeJsCanvas = ({ className }) => {
  return (
    <Canvas className={className} style={{position: 'absolute'}}>
        <ambientLight />
        <pointLight position={[100, 100, 10]} />
        <Box position={[0, 0, 0]} backwards />
        <Box position={[0, 0, 0]} />
    </Canvas>
  )
}

export default ThreeJsCanvas
