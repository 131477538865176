import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import "../sass/components/_header.scss"

const StyledHeader = styled.header`
  font-family: "VT323";
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 2;
  @include for-tablet-portrait-up {
    text-align: left;
  }
`

const Header = ({ siteTitle, menuLinks }) => (
  <StyledHeader className="header">
    <h1 className="title">
      <Link to="/" className="glitch title" data-text={siteTitle}>
        {siteTitle}
      </Link>
    </h1>
    <nav className="nav">
      <ul>
        {menuLinks.map(link => (
          <li key={link.name}>
            <Link
              style={{ color: `white` }}
              to={link.link}
              data-text={link.name}
            >
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  menuLinks: PropTypes.array,
}

Header.defaultProps = {
  siteTitle: ``,
  menuLinks: [],
}

export default Header
